<template>
  <div class="view-container eligibility-query-container p-3">

    <div class="row">
      <!-- Header -->
      <div class="col-12 mb-2">
        <h4>Search eligibility files</h4>
        <p v-if="newSystem.ready">Revision {{ client.revision }}</p>
      </div>

      <!-- Client selection -->
      <div class="row col-12 choose-client-section">
        <div class="col-12" v-if="fetching.clientsList">
          <loading-content label="Loading Clients"></loading-content>
        </div>
        <div class="row d-flex col-12 align-items-center" v-else>
          <span class="col-3">Choose client:</span>
          <clients-form-select
            class="col-4"
            v-model="selectedClientId">
          </clients-form-select>
        </div>
      </div>
    </div>

    <!-- Something went wrong section -->
    <section class="row warnings-section" v-if="error">
      <div class="col-8">
        {{ error }}
      </div>
    </section>

    <section class="row" v-if="newSystem.ready">
      <div class="col-12">
        <div class="row d-flex col-12 align-items-center" style="margin-bottom: 1rem">
          <input class="col-2 mr-3 form-control" v-model="newSystem.search.first_name" type="text"
                 placeholder="First Name" aria-label="Search">
          <input class="col-2 mr-3 form-control" v-model="newSystem.search.last_name" type="text"
                 placeholder="Last Name" aria-label="Search">
          <input class="col-1 mr-3 form-control" v-model="newSystem.search.date_of_birth" type="text"
                 placeholder="Date of Birth" aria-label="Search">
          <input class="col-2 mr-3 form-control" v-model="newSystem.search.email" type="text" placeholder="Email"
                 aria-label="Search">
          <button class="btn btn-primary float-right"
                  :disabled="newSystem.searching"
                  @click="newSystemSearch"
                  type="submit">
            {{ newSystem.searching ? "Searching" : "Search" }}
          </button>
        </div>

        <table class="table">
          <!-- Table header -->
          <thead class="thead-light">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date of Birth</th>
            <th>Email</th>
            <th>Imported At</th>
            <th>Is Dependent</th>
            <th>Detailed</th>
          </tr>
          </thead>

          <!-- Table body (entries found) -->
          <tbody>
          <tr v-for="item in newSystem.response" :key="`${item.first_name}-${item.last_name}-${item.date_of_birth}`">
            <td> {{ item.first_name }}</td>
            <td> {{ item.last_name }}</td>
            <td> {{ item.date_of_birth }}</td>
            <td> {{ item.email }}</td>
            <td> {{ formatDatetime(item.imported_at) }}</td>
            <td>
                <span v-if="item.is_dependent" class="text-success">
                  <feather type="check"></feather>
                </span>
              <span v-else class="text-danger">
                  <feather type="x"></feather>
                </span>
            </td>
            <td>
              <button class="btn btn-secondary"
                      @click="showNewSystemDetailed(item)"
                      type="submit"> Open
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Use text in props -->
        <b-pagination
            v-model="newSystem.pagination.currentPage"
            :total-rows="newSystem.pagination.rows"
            :per-page="newSystem.pagination.perPage"
            @change="changePagination"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"></b-pagination>
      </div>

      <!-- Detailed results per entry modal -->
      <b-modal id="modal" backdrop="static" :keyboard="false">
        <template v-slot:modal-title>
          Detailed entry results
        </template>
          <div class="row">
            <div class="col-6" v-for="(value, prop) in newSystem.propSelected" :key="prop">
              <strong> {{ prop }} </strong>
              <p> {{ value }} </p>
            </div>
          </div>
      </b-modal>
    </section>

  </div>
</template>

<script>

import axios from 'axios';

import moment from 'moment-timezone';
import * as R from 'ramda';
import LoadingContent from '../../components/LoadingContent.vue';
import ClientsFormSelect from '../../components/Inputs/ClientsFormSelect.vue';

export default {
  name: 'QueryFinder',
  components: {
    LoadingContent,
    ClientsFormSelect,
  },
  data() {
    this.entriesScores = [];
    return {
      fetching: {
        eligibilityConfigs: null,
        remoteEligibilityFile: null,
        clientsList: null,
      },
      error: false,
      newSystem: {
        propSelected: {},
        searching: false,
        ready: false,
        search: {
          first_name: '',
          last_name: '',
          date_of_birth: '',
          email: '',
        },
        response: [],
        pagination: {
          rows: 100,
          perPage: 2,
          currentPage: 1,
        },
      },
      selectedClientId: null,
    };
  },
  async beforeMount() {
    this.fetching.clientsList = true;
    this.$store.dispatch('Core/fetchClients').then(() => {
      this.fetching.clientsList = false;
    });
  },
  watch: {
    selectedClientId(selectedId) {
      this.error = false;
      this.fetching.eligibilityConfigs = false;
      this.fetching.remoteEligibilityFile = false;
      this.fetching.clientsList = false;
      this.client = {};
      this.newSystem = {
        propSelected: {},
        searching: false,
        ready: false,
        search: {
          first_name: '',
          last_name: '',
          date_of_birth: '',
          email: '',
        },
        response: [],
        pagination: {
          rows: 100,
          perPage: 20,
          currentPage: 1,
        },
      };

      this.checkIfClientExistsOnNewSystem(selectedId).then(client => {
        this.client = R.prop('data', client);
        this.newSystem.ready = true;
      }).catch(() => {
        this.error = 'Selected client doesn\'t have new system eligibility configured.';
      });
    },
  },
  methods: {
    formatDatetime(datetime) {
      if (!datetime) {
        return 'active';
      }

      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (_) {
        return datetime;
      }
    },
    changePagination(page) {
      this.newSystem.pagination.currentPage = page;
      this.newSystemSearch();
    },
    showNewSystemDetailed(item) {
      let payload = JSON.parse(item.payload);
      payload = R.reject(R.isEmpty, payload);
      this.newSystem.propSelected = payload;
      this.$bvModal.show('modal');
    },
    newSystemSearch() {
      this.newSystem.searching = true;
      const clientId = this.selectedClientId;

      // filter non 3 chars
      const params = R.filter(R.compose(R.lt(2), R.length), this.newSystem.search);
      if (R.compose(R.equals(0), R.length, R.keys)(params)) {
        this.error = 'Input atleast 3 characters';
        this.newSystem.searching = false;
        return;
      }

      params.page = this.newSystem.pagination.currentPage;
      params.per_page = this.newSystem.pagination.perPage;

      const cleanParams = R.mapObjIndexed(
        R.ifElse(
          R.is(String),
          R.trim,
          R.identity,
        ), params,
      );

      axios.get(`v1/clients/${clientId}/eligibility/find`, { params: cleanParams }).then(({ data }) => {
        this.newSystem.response = data.data;
        this.newSystem.pagination = {
          rows: data.total,
          perPage: data.perPage,
          currentPage: data.currentPage,
        };
      }).finally(() => {
        this.newSystem.searching = false;
      });
    },
    checkIfClientExistsOnNewSystem(clientId) {
      this.error = false;
      return axios.get(`/v1/clients/${clientId}/eligibility`);
    },
  },
};
</script>

<style lang="scss" scoped>

.view-container {
  width: 100%;
  height: 100%;
}


.eligibility-query-container {
  display: grid;
  grid: max-content max-content auto/auto;

  &.v2 .row {
    border: 1px solid red;
  }
}

.choose-client-section {
  height: 60px;
}

.warnings-section {
  align-content: center;
  justify-content: center;
  text-align: center;
  color: $shitty-grey;
}

tbody tr {
  line-height: 75px;
  min-height: 75px;
  height: 75px;
}

.table {
  tr:nth-child(even) {
    background-color: $almost-white;
  }

  .results-row {
    &:hover {
      background-color: #01b9ca38;
      cursor: zoom-in;
    }
  }
}


</style>
